<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-table
          topBorderClass="topcolor-lightblue"
          ref="grid1"
          title="평가항목"
          tableId="grid1"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="grid1.columns"
          :data="grid1.data"
          :gridHeight="gridheight"
          @rowClick="rowClick"
          :isExcelDown="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-table
          topBorderClass="topcolor-lightblue"
          ref="grid2"
          title="구분"
          tableId="grid2"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="grid2.columns"
          :data="grid2.data"
          :gridHeight="gridheight"
          @rowClick="rowClick2"
          :isExcelDown="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          topBorderClass="topcolor-lightblue"
          ref="grid3"
          title="평가항목별 세부항목"
          tableId="grid3"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="grid3.columns"
          :data="grid3.data"
          :gridHeight="gridheight"
          :isExcelDown="false"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="selfInspectionResultItemId"
                ibmTaskTypeCd="ITT0000035"
                ibmTaskUnderTypeCd="ITTU000060"
                @imprChange="imprChange"
              />
            </template>
          </template>
          <template slot="table-button">
            <div>
              <q-btn-group outline>
                <c-btn v-if="editable && popupParam.selfInspectionStatusCd == 'SISC000002'" label="저장" icon="save" @btnClicked="saverow" />
              </q-btn-group>
            </div>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazop-plan-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      requestImprRow: null,
      imprData: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      gridheight: '',
      grid1: {
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '평가항목',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            required: true,
            name: 'itemTypeName',
            field: 'itemTypeName',
            label: '구분',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid3: {
        columns: [],
        data: [],
      },
      list1Url: '',
      list2Url: '',
      list3Url: '',
      updateUrl: '',
      infoUrl: '',
      editable: true,
      colorItems: [],
      selectedLastRow: {},
      data: {
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        chgUserId: '',
        sysApprovalRequestId: '',
        approvalTypeCd: '',
        approvalStatusCd: '',
        approvalStatusName: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.selfInspectionStatusCd != 'SISC000001' && this.data.selfInspectionStatusCd != 'SISC000002'
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['itemName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  methods: {
    init() {
      this.gridheight = (window.innerHeight - 208) + 'px';
      // code setting
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      this.editable = this.$route.meta.editable;
      this.infoUrl = selectConfig.sop.pca.result.info.get.url;
      this.list1Url = selectConfig.sop.pca.item.revs.url;
      this.list2Url = selectConfig.sop.pca.type.list.url;
      this.list3Url = selectConfig.sop.pca.itemsresult.mst.url;
      this.updateUrl = transactionConfig.sop.pca.result.item.save.url;

      this.$comm.getComboItems('SELF_INSPECTION_RESULT_CD').then(_result => {
        this.grid3.columns = [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'No.',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '세부항목',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'selfInspectionResultCd',
            field: 'selfInspectionResultCd',
            label: '평가',
            align: 'center',
            setHeader: true,
            type: 'select',
            comboItems: _result,
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'selfInspectionRemark',
            field: 'selfInspectionRemark',
            label: '평가 근거',
            align: 'left',
            type: 'textarea',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'answerUserName',
            field: 'answerUserName',
            label: '답변자',
            align: 'center',
            sortable: false,
            type: 'user',
            userId: 'answerUserId',
            value: '',
            setHeader: true,
            style: 'width:100px',
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            style: 'width:200px',
            type: 'custom',
            sortable: false
          },
        ]
        this.getInfo();
        this.getList();
      });
    },
    getInfo() {
      this.$http.url = this.infoUrl;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.data = _result.data;
      },);
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 1) {
        for(let c = 1; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getList() {
      this.$http.url = this.list1Url;
      this.$http.type = 'GET';
      this.$http.param = {sysRevision: this.popupParam.sysRevision};
      this.$http.request((_result) => {
        this.selectedLastRow = {};
        this.grid1.data = _result.data;
      },);
    },
    rowClick(row) {
      this.selectedLastRow = {};
      this.$http.url = this.list2Url;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionItemMstId: row.selfInspectionItemMstId
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
        this.grid3.data = [];
        if (this.grid2.data.length === 1) {
          this.rowClick2({selfInspectionItemTypeId: this.grid2.data[0].selfInspectionItemTypeId, itemTypeName: this.grid2.data[0].itemTypeName});
        }
      },);
    },
    rowClick2(row) {
      this.selectedLastRow = row;
      this.$http.url = this.list3Url;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionItemTypeId: row.selfInspectionItemTypeId,
        selfInspectionResultId: this.popupParam.selfInspectionResultId
      }
      this.$http.request((_result) => {
        this.grid3.data = _result.data;
      },);
    },
    saverow() {
      let saveData = this.grid3.data.filter( x => {
        return x.editFlag == 'U'
      });
      this.$_.forEach(saveData, item => {
        item.selfInspectionResultId = this.popupParam.selfInspectionResultId;
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      // selfInspectionResultId
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.updateUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.rowClick2(this.selectedLastRow);
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.rowClick2(this.selectedLastRow);
      this.$refs['grid3'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
